import React from "react";
import welcomeImage from "../../assets/images/welcome-img.png";
import "./index.scss";
import TextSlider from "../textSlider";

const WelcomeSection = () => {
  const slides = [
    "Your Trusted Source for High-Quality Plastic Solutions.",
    "Explore our wide range of precision-manufactured plastic pipes, junctions, and caps designed to meet your every need.",
    "Welcome to Kamal Plastic – Your Experience durability and innovation with Kamal Plastic Crafting Excellence in Every Product.",
  ];
  return (
    <div className="welcomesection-outer-div">
      <div className="welcomesection">
        <div className="welcomesectiontext">
          <TextSlider slides={slides} />
        </div>
        <div className="welcomesectionimage">
          <img className="welcomeImage" src={welcomeImage} alt="welcomeImage" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
