import React from "react";
import "./index.scss";
import about from "../../assets/images/aboutimage.png";
import line from "../../assets/images/aboutline.png";
import logo from "../../assets/images/logo.jpg";
import owner from "../../assets/images/owner.jpg";
import kamal from "../../assets/images/kamal_logo.png";
import rajal1 from "../../assets/images/Rajal_logo1.png";

const About = () => {
  return (
    <div className="aboutimages">
      <img className="topimage" src={about} alt="kamalPlastics" />
      <div className="title">About Kamal Plastics</div>
      <div className="long-brown-line">
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="block1">
        <div className="left1">
          <p>
            <br></br>
            <b>KAMALPLASTICS</b> is one of the finest PVC end product based
            manufacturers & located in TINDIVANAM,TAMIL NADU. It was established
            in the year 1989 by{" "}
            <b>
              <i>VENKATASESHIYA B</i>
            </b>
            . <br />
            Our proprietor was from a poor financial background and so kamal
            plastic was not a behemoth during the time of start,but now it’s
            quite contrary. And we desperately showing transformation from level
            zero to a level where we are having a ground to perform and shine in
            market. <br />
            This change of context exhibits the quality of our products we
            produced since day one. The quality of our product created huge
            impact on the customers and helped/helping us to improvise in
            quantity point and having reach almost many places in tamilnadu.
          </p>
        </div>
        <div className="right1">
          <img className="aboutlogo" src={logo} alt="kamalPlastics" />
        </div>
      </div>
      <div className="title">Mission & Vision</div>
      <div className="long-brown-line">
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="vblock1">
        <div className="vleft1">
          <img className="aboutowner" src={owner} alt="kamalPlastics" />
        </div>
        <div className="vright1">
          <div className="vtop">
            <div className="vtitle">Mission</div>
            <p>
              Empowering Connections, Enriching Lives: We are dedicated to
              delivering high-quality PVC wiring pipes and junctions, fostering
              sustainable solutions, and creating lasting partnerships to
              enhance the connectivity and well-being of communities we serve.
              <br></br>
            </p>
          </div>
          <div className="vmiddle"></div>
          <div className="vbottom">
            <div className="vtitle">Vision</div>
            <p>
              At Kamal Plastics, our vision is to expand and expand our sales
              capabilities in order to specifically address the market's rising
              demands and maintain our position of excellence in the plastics
              industry.
            </p>
            <br></br>
          </div>
        </div>
      </div>
      <div className="title">Our Brands</div>
      <div className="long-brown-line">
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="bblock1">
        <div className="bleft">
          <div className="vtitle">
            <img className="brandlogo" src={kamal} alt="kamalPlastics" />
          </div>
          <p>
            Our main brand, Kamal, has been around for over 20 years and is
            known for top-notch quality, classy finishes, and a range of colors
            like Natural, Gray, and Blue. We pack our products neatly for you.
          </p>
          <br></br>
        </div>
        <div className="bright">
          <div className="vtitle">
            <img className="rbrandlogo" src={rajal1} alt="kamalPlastics" />
          </div>
          <p>
            Our baby brand, Rajal, started in 2023 and has become a hit with
            customers. We offer great prices without sacrificing quality, and
            our products are neatly packed.
          </p>
          <br></br>
        </div>
      </div>
      <div className="advtitle">Advantages of Choosing Us</div>
      <div className="long-brown-line" style={{ marginBottom: "10px" }}>
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="adv">
        <div className="whole">
          <div className="head">
            <p>1. High-Quality Plastic Products</p>
            <div className="content">
              <p>
                Our commitment to excellence ensures that every product we offer
                meets stringent quality standard, guaranteeing reliability &
                longevity
              </p>
            </div>
          </div>
        </div>
        <div className="whole">
          <div className="head">
            <p>2. Competitive Pricing</p>
            <div className="content">
              <p>
                We offer competitive pricing without compromising on quality,
                ensuring you receive excellent value for your investment.
              </p>
            </div>
          </div>
        </div>
        <div className="whole">
          <div className="head">
            <p>3. Superior Packaging</p>
            <div className="content">
              <p>
                We ensure high-quality packaging for your products. Your
                products are carefully packaged to ensure they arrive in perfect
                condition.
              </p>
            </div>
          </div>
        </div>
        <div className="whole">
          <div className="head">
            <p>4. Efficient Production Process and Timely Delivery</p>
            <div className="content">
              <p>
                Our streamlined production process ensures efficiency and
                precision, enabling us to meet your requirements seamlessly and
                deliver your orders on time.
              </p>
            </div>
          </div>
        </div>
        <div className="whole">
          <div className="head">
            <p>5. Wide Retailer Network</p>
            <div className="content">
              <p>
                With over 450 retailers, our products are easily accessible
                across various locations, making it convenient for you to
                purchase from us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
