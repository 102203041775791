import React from "react";
// import { useRef } from "react";
import Contact from "../contact";
import "./index.scss";
// import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import phone from "../../assets/icons/phoneicon.png";
import location from "../../assets/icons/locationicon.png";
import message from "../../assets/icons/messageicon.png";

const ContactPage = () => {
  return (
    <div className="contact-page">
      {/* onSubmit={sendEmail} */}
      <div className="contact_map_section">
        <div className="contact-section">
          <Contact />
        </div>
        <div className="map-wrap">
          <div className="map" style={{ width: "100%" }}>
            <iframe
              title="map_iframe"
              width="720"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=KAMAL%20PLASTICS,%20No.14%20Mohammed%20Nabi%20Street,%20Roshanai,%20Tindivanam%20-%20604001+(%20Kamal%20Plastics)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps systems</a>
            </iframe>
          </div>
        </div>
      </div>
      <div className="contact-info">
        <div className="phone-section">
          <div className="phone_title_section">
            <h1 className="phone-title">Phone</h1>
          </div>
          <div className="phone-inner-section">
            <div className="phone_inner">
              <div className="phone_image2">
                <img src={phone} alt="phone" className="phone_img" />
              </div>
              <p>(+91) 9443397250</p>
            </div>
            <br />
            <div className="phone_inner">
              <div className="phone_image2">
                <img src={phone} alt="phone" className="phone_img" />
              </div>
              <p>(+91) 9486387322</p>
            </div>
          </div>
        </div>
        <div className="email-section">
          <div className="email_title_section">
            <h1 className="email-title">Email</h1>
          </div>
          <div className="email-inner">
            <div className="email_image2">
              <img
                src={message}
                alt="email_image_icon"
                className="email_image_icon"
              />
            </div>
            <p>kamalplastic1989@gmail.com</p>
          </div>
        </div>
        <div className="address-section">
          <div className="address_title_section">
            <h1 className="address-title">Address</h1>
          </div>
          <div className="address-inner">
            <div className="location_image">
              <img
                src={location}
                alt="location_image_icon"
                className="location_image_icon"
              />
            </div>
            <p>
              KAMAL PLASTICS, No.14 Mohammed Nabi Street, Roshanai, Tindivanam -
              604001
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
