import React from "react";
import { useState } from "react";
import "./index.scss";

const PackageCard = ({ title, image, description, alt, number }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="packagesubdiv">
      <div className="subtitle">
        <h2>{title}</h2>
      </div>
      <div className="package-content">
        <div className="description">
          <p>{description}</p>
          <br />
          <p>
            <b>{number}</b>
          </p>
        </div>
        <div className="image">
          <img src={image} alt={alt} onClick={() => openModal(`${image}`)} />
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modalbox">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <img
              width="750vw"
              height="500vh"
              className="modal-content"
              src={currentImage}
              alt="Large Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageCard;
