import React from "react";
import "./index.scss";
import Links from "../links";
import map from "../../assets/images/maps.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";
import home from "../../assets/images/home.png";
import { Element } from "react-scroll";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Element name="footer">
      <div className="footer">
        <div className="footer_inside">
          <div className="about_footer">
            <div className="about_title_container">
              <h2 className="about_title">KAMAL PLASTICS</h2>
            </div>
            <div className="about_description">
              <p>
                Dedicated to delivering high-quality PVC wiring solutions, we
                foster sustainability and lasting partnerships. Our mission is
                to enhance connectivity and well-being, empowering communities
                through top-notch pipes and junctions.
              </p>
            </div>
          </div>
          <div className="quicklink_footer">
            <div className="quicklink_title_container">
              <h2 className="quicklink_title">Quick Links</h2>
            </div>
            <div className="links-container">
              <div className="home_container">
                <img src={home} alt="home" className="homeIcon" />
                <NavLink exact to="/" activeClassName="active" className="link">
                  Home
                </NavLink>
              </div>
              <Links />
            </div>
          </div>
          <div className="reach_us_footer">
            <div className="reach_us_title_container">
              <h2 className="reach_us_title">Reach Us</h2>
            </div>
            <div className="reach_container">
              <div className="reach_container_inside">
                <div className="email_container">
                  <div className="email_image">
                    <img src={email} alt="email" />
                  </div>
                  <div className="email">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="mailto: kamalplastic1989@gmail.com"
                    >
                      kamalplastic1989@gmail.com
                    </a>
                  </div>
                </div>
                <div className="phone_container">
                  <div className="phone_image">
                    <img src={phone} alt="phone" />
                  </div>
                  <div className="phone">
                    <p>9443397250 | 9486387322</p>
                  </div>
                </div>
                <div className="address_container">
                  <div className="map_image">
                    <img src={map} alt="map" />
                  </div>
                  <div className="address">
                    <span>KAMAL PLASTICS</span>
                    <p>
                      No.14 Mohammed Nabi Street, Roshanai, Tindivanam - 604001
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Footer;
