import "./index.scss";

import React from "react";
import line from "../../assets/images/aboutline.png";
import package1 from "../../assets/images/packages/1.jpg";
import package2 from "../../assets/images/packages/2.jpg";
import package3 from "../../assets/images/packages/3.jpg";
import package4 from "../../assets/images/packages/4.jpg";
import package5 from "../../assets/images/packages/5.jpg";
import PackageCard from "../packageCard";
const Packing = () => {
  const packageData = [
    {
      title: "1. Packing of Junction Box",
      alt: "image for package 1",
      image: package1,
      description:
        " Our Junction Boxes are meticulously packed in high-quality,durable bags to ensure their safety during transit and storage.",
      number: "(Each bag contains 100 pieces)",
    },
    {
      title: "2. Packing of Ceiling Plate",
      alt: "image for package 2",
      image: package2,
      description:
        "Our Ceiling Plates are securely packed in sturdy boxes to prevent any damage and ensure they arrive in perfect condition.",
      number: "(Each box contains 100 pieces)",
    },
    {
      title: "3. Packing of Fan Plate",
      alt: "image for package 3",
      image: package3,
      description:
        "Fan Plates are carefully packed in robust boxes, providing excellent protection and facilitating safe delivery.",
      number: "(Each box contains 100 pieces)",
    },
    {
      title: "4. Packing of Spot Box",
      alt: "image for package 4",
      image: package4,
      description:
        "Each Spot Box is individually packed with a protective cover, ensuring it remains pristine and undamaged until use.",
    },
    {
      title: "5. Packing of Fan Box",
      alt: "image for package 5",
      image: package5,
      description:
        "Fan Boxes are individually packed with a durable cover to maintain their quality and integrity.",
    },
  ];
  return (
    <div className="packingDiv">
      <div className="title">
        <p>Our Packing</p>
      </div>
      <div className="long-brown-line">
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="maincontent">
        <p>
          <b>
            At Kamal Plastics,we take pride in delivering not just exceptional
            products but also an outstanding unboxing experience. Our packaging
            is designed with meticulous attention to detail, ensuring that your
            purchase arrives in perfect condition every time.
          </b>
        </p>
      </div>
      {packageData.map((data, idx) => (
        // console.log(slidesData);
        <PackageCard
          title={data.title}
          index={idx}
          alt={data.alt}
          image={data.image}
          description={data.description}
          number={data.number}
        />
      ))}
      {/* <div className="subtitle">

      </div> */}
    </div>
  );
};

export default Packing;
