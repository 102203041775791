import React from "react";
import "./index.scss";
import logo from "../../assets/icons/logo.png";
import hamburger from "../../assets/icons/hamsburger.png";
import hamburger2 from "../../assets/icons/hamsburger2.png";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Links from "../links";
// import DropDown from "../DropDownMenu";

const Navbar = ({ kamalslidesDatas, rajalslidesDatas }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () =>
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  return (
    <div className="navbar">
      {isExpanded && (
        <div className="menu-overlay2">
          <div className="menuimagerow">
            <a href="/">
              <img className="logo" src={logo} alt="logo" />
            </a>
            <button className="hamburger" onClick={toggleExpanded}>
              <img
                src={hamburger2}
                className="hamburger-img2"
                alt="hamburger"
              />
            </button>
          </div>
          <div className="links">
            <NavLink
              exact
              to="/kamal"
              activeClassName="active"
              className="link"
              onClick={toggleExpanded}
            >
              Kamal Products
            </NavLink>
            <NavLink
              to="/rajal"
              activeClassName="active"
              className="link"
              onClick={toggleExpanded}
            >
              Rajal Products
            </NavLink>

            <NavLink
              to="/packing"
              activeClassName="active"
              className="link"
              onClick={toggleExpanded}
            >
              Our Packing
            </NavLink>
            <NavLink
              to="/about"
              activeClassName="active"
              className="link"
              onClick={toggleExpanded}
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              activeClassName="active"
              className="link"
              onClick={toggleExpanded}
            >
              Contact Us
            </NavLink>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://forms.gle/CNygdKRxZfUsAD4o6"
              className="link"
            >
              Get Quote
            </a>
          </div>
        </div>
      )}
      {/* <div className="logo-div">
        <NavLink exact to="/">
          <img className="logo" src={logo} alt="logo" />
        </NavLink>
      </div> */}
      <div className="menu-overlay1">
        <Links
          kamalslidesDatas={kamalslidesDatas}
          rajalslidesDatas={rajalslidesDatas}
        />
      </div>
      <button className="hamburger" onClick={toggleExpanded}>
        <img src={hamburger} className="hamburger-img" alt="hamburger" />
      </button>
    </div>
  );
};

export default Navbar;
