import React from "react";
import "./index.scss";
import phone from "../../assets/icons/phone.png";
import email from "../../assets/icons/email.png";

import logo from "../../assets/icons/logo.png";

const Header = () => {
  return (
    <div className="header">
      <div className="classnamediv">
        <a href="/">
          <div className="logo-div">
            <img className="logo" src={logo} alt="logo" />
            <div className="logo-text-div">
              <p>GSTIN : 33ACTPV4470G1Z4</p>
            </div>
          </div>
        </a>
      </div>
      <div className="contact_section">
        <div className="cell_section">
          <img className="phone" src={phone} alt="phone" />
          <p>Cell : 9443397250 | 9486387322</p>
        </div>
        <div className="email_section">
          <img className="email_icon" src={email} alt="email" />
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto: kamalplastic1989@gmail.com"
          >
            Email : kamalplastic1989@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
