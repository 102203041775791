import React from "react";
import { useRef } from "react";
import "./index.scss";
import underLine from "../../assets/images/underLine.png";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const refForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_oubf5g8", "template_6n82ntv", refForm.current, {
        publicKey: "CLldRX7G-4BtVCjVj",
      })
      .then(
        () => {
          alert("Message successfully sent!");
          window.location.reload(false);
        },
        () => {
          alert("Failed to send the message, please try again");
        }
      );
  };
  return (
    <div className="contact">
      <div className="contact-inner-div">
        <div className="contactHeading">
          <span>Contact Form</span>
        </div>
        <div className="headingLineDiv">
          <img src={underLine} alt="underLine" className="underLine" />
        </div>
        <div className="contact-form-outerdiv">
          <div className="contact-form-innerdiv">
            <div className="contact-form">
              <form ref={refForm} onSubmit={sendEmail}>
                <ul>
                  <div className="first-li">
                    <li className="half">
                      <input
                        placeholder="Name"
                        type="text"
                        name="from_name"
                        required
                      />
                    </li>
                    <li className="half">
                      <input
                        placeholder="Email"
                        type="email"
                        name="email"
                        required
                      />
                    </li>
                  </div>
                  <div className="second-li">
                    <li className="half">
                      <input
                        placeholder="mobile number"
                        type="number"
                        name="contact_number"
                        required
                      />
                    </li>

                    <li className="half">
                      <input
                        placeholder="Whatsapp number"
                        type="number"
                        name="whatsapp_number"
                        required
                      />
                    </li>
                  </div>
                  <div className="third-li">
                    <li>
                      <input
                        placeholder="Subject"
                        type="text"
                        name="subject"
                        required
                      />
                    </li>
                    <li>
                      <textarea
                        placeholder="Message"
                        name="message"
                        required
                      ></textarea>
                    </li>

                    <li>
                      <input
                        type="submit"
                        className="flat-button"
                        value="SUBMIT"
                      />
                    </li>
                  </div>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
