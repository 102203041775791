import React, { useState, useEffect } from "react";
import "./index.scss"; // Include your CSS file for styling

const TextSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="text-slider-container">
      <div className="text-slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slidetext ${index === currentIndex ? "active" : ""}`}
          >
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextSlider;
