import React from "react";
import "./index.scss";
import pline from "../../assets/images/productline.png";
import line from "../../assets/images/aboutline.png";
import logo from "../../assets/images/logo.jpg";
import industry from "../../assets/images/Industryexperience.png";
import ourproduct from "../../assets/images/Ourproduct.png";
import productionunit from "../../assets/images/Productionunit.png";

const Mainpage = () => {
  return (
    <div className="aboutimages">
      <div className="title">Kamal Plastic Services</div>
      <div className="long-brown-line">
        <img className="lineimage" src={line} alt="kamalPlastics" />
      </div>
      <div className="maincontent">
        <p>
          Welcome to KAMAL PLASTICS, we specialise in creating high-quality PVC
          products that are durable, versatile, designed to last and aesthetic
          as well. We're committed to using the latest in PVC manufacturing
          technology at high precision to create products that meet the highest
          standards of quality.
        </p>
      </div>
      <div className="bblock1">
        <div className="pleft">
          <div className="vtitle">
            <img className="ourproduct" src={ourproduct} alt="kamalPlastics" />
          </div>
          <div>
            <div className="ptitle" style={{ marginLeft: "25px" }}>
              Our Products
            </div>
            <div className="plong-brown-line">
              <img className="plineimage" src={pline} alt="kamalPlastics" />
            </div>
          </div>
          <p>
            Some of our featured products include Fan Boxes, Spot Boxes, Ceiling
            Plates, PVC Fittings, and more. Explore our full range and find the
            perfect solution for your needs.
          </p>
          <br></br>
        </div>
        <div className="pright">
          <div className="vtitle">
            <img
              className="productionunit"
              src={productionunit}
              alt="kamalPlastics"
            />
          </div>
          <div>
            <div className="ptitle" style={{ marginLeft: "15px" }}>
              Production Unit
            </div>
            <div className="plong-brown-line">
              <img className="plineimage" src={pline} alt="kamalPlastics" />
            </div>
          </div>
          <p>
            At kamal plastics, we utilize Injection moulding technology to
            produce over 100 high-quality plastic products daily, free from
            defects. With a dedicated team of 20+ members, we ensure seamless
            production in our 7500 sq.ft. space.
          </p>
          <br></br>
        </div>
      </div>
      <div className="blockcenter">
        <div className="pcenter">
          <div className="vtitle">
            <img
              className="productionunit"
              src={industry}
              alt="kamalPlastics"
            />
          </div>
          <div>
            <div className="ptitle">Industry Experience</div>
            <div className="plong-brown-line">
              <img className="plineimage" src={pline} alt="kamalPlastics" />
            </div>
          </div>
          <p>
            Since 1989, kamal plastics has been a strong presence in the plastic
            industry, evolving from traditional to modern technology with each
            industry revolution. Today, with over 30 years of expertise, we
            proudly supply for 450+ retailers, delivering well packaged superior
            quality products.
          </p>
          <br></br>
        </div>
      </div>
      <div>
        <div
          className="title"
          style={{ marginLeft: "50px", marginTop: "15px" }}
        >
          About Kamal Plastics
        </div>
        <div className="long-brown-line">
          <img className="lineimage" src={line} alt="kamalPlastics" />
        </div>
      </div>
      <div className="block1">
        <div className="left1">
          <p>
            <br></br>
            <b>KAMALPLASTICS</b> is one of the finest PVC end product based
            manufacturers & located in TINDIVANAM,TAMIL NADU. It was established
            in the year 1989 by{" "}
            <b>
              <i>VENKATASESHIYA B</i>
            </b>
            . <br />
            Our proprietor was from a poor financial background and so kamal
            plastic was not a behemoth during the time of start,but now it’s
            quite contrary. And we desperately showing transformation from level
            zero to a level where we are having a ground to perform and shine in
            market. <br />
            This change of context exhibits the quality of our products we
            produced since day one. The quality of our product created huge
            impact on the customers and helped/helping us to improvise in
            quantity point and having reach almost many places in tamilnadu.
          </p>
        </div>
        <div className="right1">
          <img className="aboutlogo" src={logo} alt="kamalPlastics" />
        </div>
      </div>
    </div>
  );
};

export default Mainpage;
