import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import Navbar from "./components/navbar";
import Slider from "./components/slider";
import WelcomeSection from "./components/welcome";
import About from "./components/about";
import ContactPage from "./components/ContactPage";
import Mainpage from "./components/mainpage";
import Products from "./components/products";
import deepgold1 from "./assets/images/deepgold/2.png";
import deepgold2 from "./assets/images/deepgold/3.png";
import deepgold25mm1 from "./assets/images/deepgold25mm/1.png";
import deepgold25mm2 from "./assets/images/deepgold25mm/2.png";
import deepgold25mm3 from "./assets/images/deepgold25mm/3.png";
import deep19mm1 from "./assets/images/deep19mm/1.png";
import deep25mm1 from "./assets/images/deep25mm/1.png";
import deep25mm2 from "./assets/images/deep25mm/2.png";
import open19mm1 from "./assets/images/open19mm/1.png";
import open19mm2 from "./assets/images/open19mm/2.png";
import open25mm1 from "./assets/images/open25mm/1.png";
import open25mm2 from "./assets/images/open25mm/2.png";
import fanbox110mm1 from "./assets/images/fanbox110mm/1.png";
import fanbox110mm2 from "./assets/images/fanbox110mm/2.png";
import fanbox120mm1 from "./assets/images/fanbox120mm/1.png";
import fanbox120mm2 from "./assets/images/fanbox120mm/2.png";
import metalbox1 from "./assets/images/metalbox/1.png";
import metalbox2 from "./assets/images/metalbox/2.png";
import spotbox3inch1 from "./assets/images/spotbox3/1.png";
import spotbox3inch2 from "./assets/images/spotbox3/2.png";
import spotbox4inch1 from "./assets/images/spotbox4/1.png";
import insulator2way1 from "./assets/images/insulator2way/1.png";
import insulator4way1 from "./assets/images/insulator4way/1.png";
import elbow19mm1 from "./assets/images/elbow19mm/1.png";
import elbow25mm1 from "./assets/images/elbow25mm/1.png";

import spotbox4inch2 from "./assets/images/spotbox4/2.png";
import ceilingplatelight1 from "./assets/images/ceilingplatelight/1.png";
import ceilingplatelight2 from "./assets/images/ceilingplatelight/2.jpg";
import ceilingplatelight3 from "./assets/images/ceilingplatelight/3.jpg";
import ceilingplateheavy1 from "./assets/images/ceilingplateheavy/1.png";
import ceilingplateheavy2 from "./assets/images/ceilingplateheavy/2.png";
import fanceilingplate5 from "./assets/images/5fanceilingplate/1.png";
import fanceilingplate5_2 from "./assets/images/5fanceilingplate/2.jpg";
import fanceilingplate6 from "./assets/images/6fanceilingplate/1.png";
import finolexbend25mm1 from "./assets/images/finolexbend25mm/1.png";
import finolexbend25mm2 from "./assets/images/finolexbend25mm/2.png";
import finolexbend19mm1 from "./assets/images/finolexbend19mm/1.jpg";
import finolexbendmms25mm1 from "./assets/images/finolexbendmms25mm/1.png";
import finolexbendmms25mm2 from "./assets/images/finolexbendmms25mm/2.png";
import roundblock1 from "./assets/images/roundblock/1.png";
import virat15mmbend19mm1 from "./assets/images/virat1.5mmbend19mm/1.png";
import virat15mmbend19mm2 from "./assets/images/virat1.5mmbend19mm/2.png";
import virat15mmbend25mm1 from "./assets/images/virat1.5mmbend25mm/1.png";
import virat15mmbend25mm2 from "./assets/images/virat1.5mmbend19mm/2.png";
import virat20mmbend19mm1 from "./assets/images/virat2.0mmbend19mm/1.png";
import virat20mmbend19mm2 from "./assets/images/virat2.0mmbend19mm/2.png";
import virat20mmbend25mm1 from "./assets/images/virat2.0mmbend25mm/1.png";
import virat20mmbend25mm2 from "./assets/images/virat2.0mmbend25mm/2.png";
import openjunction19mm1 from "./assets/images/rajal/openjunction19mm/1.png";
import openjunction25mm1 from "./assets/images/rajal/openjunction25mm/1.png";
import tee25mm1 from "./assets/images/tee19mm/1.png";
import tee19mm1 from "./assets/images/tee25mm/1.png";
import rajaldeep19mm1 from "./assets/images/rajal/deep19mm/1.png";
import rajaldeep25mm1 from "./assets/images/rajal/deep25mm/1.png";
import fanbox1 from "./assets/images/rajal/fanbox/1.png";
import fanbox2 from "./assets/images/rajal/fanbox/2.jpg";
import ceilingplaterajal1 from "./assets/images/rajal/ceilingplate/1.png";
import bend19mm1 from "./assets/images/rajal/bend19mm/1.png";
import bend25mm1 from "./assets/images/rajal/bend25mm/1.png";
import spotbox1 from "./assets/images/rajal/spotbox/1.png";
import spotbox2 from "./assets/images/rajal/spotbox/2.png";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Packing from "./components/Packing";

function App() {
  const kamalslidesDatas = [
    {
      images: [
        {
          src: deepgold1,
          alt: "Image 1 for carousel",
        },
        {
          src: deepgold2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "19mm Deep Gold (3mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.13/piece  + GST",
    },
    {
      images: [
        {
          src: deepgold25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: deepgold25mm2,
          alt: "Image 2 for carousel",
        },
        {
          src: deepgold25mm3,
          alt: "Image 3 for carousel",
        },
      ],
      description: "25mm Deep Gold (3mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.14/piece  + GST",
    },
    {
      images: [
        {
          src: deep19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "19mm Deep(2mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.9/piece  + GST",
    },
    {
      images: [
        {
          src: deep25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: deep25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25mm Deep(2mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.10/piece  + GST",
    },
    {
      images: [
        {
          src: open19mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: open19mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "19mm  Open(3mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.6.50/piece  + GST",
    },
    {
      images: [
        {
          src: open25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: open25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25mm Open(3mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.7.50/piece  + GST",
    },
    {
      images: [
        {
          src: fanbox110mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: fanbox110mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "PVC circular box(100mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.52/piece  + GST",
    },
    {
      images: [
        {
          src: fanbox120mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: fanbox120mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "PVC circular box(120mm)",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.57/piece  + GST",
    },
    {
      images: [
        {
          src: metalbox1,
          alt: "Image 1 for carousel",
        },
        {
          src: metalbox2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "Metal fan box(Powder coated 650 gms)",
      color: "Color: Ivory.",
      rate: "Rs.84/piece  + GST",
    },
    {
      images: [
        {
          src: elbow19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "19MM ELBOW HEAVY",
      color: "Color: Gray.",
      rate: "Rs.1.75/piece  + GST",
    },
    {
      images: [
        {
          src: elbow25mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "25MM ELBOW HEAVY",
      color: "Color: Gray and Ivary.",
      rate: "Rs.2.75/piece  + GST",
    },
    {
      images: [
        {
          src: spotbox3inch1,
          alt: "Image 1 for carousel",
        },
        {
          src: spotbox3inch2,
          alt: "Image 2 for carousel",
        },
      ],
      description: '3"SPOT BOX-6WAY',
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.23/piece  + GST",
    },
    {
      images: [
        {
          src: spotbox4inch1,
          alt: "Image 1 for carousel",
        },
        {
          src: spotbox4inch2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "4“ SPOT BOX[HEAVY]",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.23/piece  + GST",
    },
    {
      images: [
        {
          src: tee25mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "25MM Tee HEAVY",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.3.25/piece  + GST",
    },
    {
      images: [
        {
          src: tee19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "19MM Tee HEAVY",
      color: "Colors: virgin, gray, and blue.",
      rate: "Rs.2.25/piece  + GST",
    },
    {
      images: [
        {
          src: insulator2way1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "2 WAY(REEL) INSULATOR",
      color: "Color: Red",
      rate: "Rs.1.80/piece  + GST",
    },
    {
      images: [
        {
          src: insulator4way1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "4 WAY(REEL) INSULATOR",
      color: "VIRGIN, GRAY, BLUE",
      rate: "Rs.3.50/piece  + GST",
    },
    {
      images: [
        {
          src: ceilingplateheavy1,
          alt: "Image 1 for carousel",
        },
        {
          src: ceilingplateheavy2,
          alt: "Image 1 for carousel",
        },
      ],
      description: "CEILING PLATE [HEAVY (17grams)]",
      color: "Color: MILK WHITE",
      rate: "Rs.3.50/piece  + GST",
    },
    {
      images: [
        {
          src: ceilingplatelight1,
          alt: "Image 1 for carousel",
        },
        {
          src: ceilingplatelight2,
          alt: "Image 2 for carousel",
        },
        {
          src: ceilingplatelight3,
          alt: "Image 3 for carousel",
        },
      ],
      description: "CEILING PLATE [LIGHT (12grams)]",
      color: "Color: MILK WHITE",
      rate: "Rs.2.50/piece  + GST",
    },
    {
      images: [
        {
          src: fanceilingplate5,
          alt: "Image 1 for carousel",
        },
        {
          src: fanceilingplate5_2,
          alt: "Image 2s for carousel",
        },
      ],
      description: '5" FAN CEILING PLATE- HEAVY(33grams)',
      color: "Color: MILK WHITE",
      rate: "Rs.7.50/piece  + GST",
    },
    {
      images: [
        {
          src: fanceilingplate6,
          alt: "Image 1 for carousel",
        },
      ],
      description: '6" FAN CEILING PLATE - HEAVY(50grams)',
      color: "Color: MILK WHITE",
      rate: "Rs.10/piece  + GST",
    },
    {
      images: [
        {
          src: roundblock1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "ROUND BLOCK HEAVY",
      color: "Color: MILK WHITE",
      rate: "Rs.6.25/piece  + GST",
    },
    {
      images: [
        {
          src: finolexbend25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: finolexbend25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25MM PVC FINOLEX BRND",
      color: "Color: Gray",
      rate: "Rs.5/piece  + GST",
    },
    {
      images: [
        {
          src: finolexbend19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "19MM PVC FINOLEX BEND (MMS)",
      color: "Color:  GRAY, ivary",
      rate: "Rs.4/piece  + GST",
    },
    {
      images: [
        {
          src: finolexbendmms25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: finolexbendmms25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25MM PVC FINOLEX BEND (MMS)",
      color: "Color:  GRAY, ivary",
      rate: "Rs.5/piece  + GST",
    },
    {
      images: [
        {
          src: virat15mmbend19mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: virat15mmbend19mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "19MM PVC FRLS BEND(1.5MM)",
      color: "Color: Gray",
      rate: "Rs.4.50/piece  + GST",
    },
    {
      images: [
        {
          src: virat15mmbend25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: virat15mmbend25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25MM PVC FRLS BEND (1.5MM)",
      color: "Color:  GRAY",
      rate: "Rs.5.50/piece  + GST",
    },
    {
      images: [
        {
          src: virat20mmbend19mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: virat20mmbend19mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "19MM PVC FRLS BEND (2.0MM)",
      color: "Color:  GRAY",
      rate: "Rs.5.50/piece  + GST",
    },
    {
      images: [
        {
          src: virat20mmbend25mm1,
          alt: "Image 1 for carousel",
        },
        {
          src: virat20mmbend25mm2,
          alt: "Image 2 for carousel",
        },
      ],
      description: "25MM PVC FRLS BEND(2.0MM)",
      color: "Color:  GRAY",
      rate: "Rs.6.50/piece  + GST",
    },
  ];

  const rajalslidesDatas = [
    {
      images: [
        {
          src: openjunction19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "Rajal 19mm Open",
      color: "Colors: MILK WHITE, GREY",
      rate: "Rs.5.50/piece  + GST",
    },
    {
      images: [
        {
          src: openjunction25mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "Rajal 25mm Open",
      color: "Colors: MILK WHITE, GREY",
      rate: "Rs.6.50/piece  + GST",
    },
    {
      images: [
        {
          src: rajaldeep19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "Rajal 19mm Deep",
      color: "Colors: MILK WHITE, GREY",
      rate: "Rs.8/piece  + GST",
    },
    {
      images: [
        {
          src: rajaldeep25mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "Rajal 25mm Deep",
      color: "Colors: MILK WHITE, GREY",
      rate: "Rs.9/piece  + GST",
    },
    {
      images: [
        {
          src: fanbox1,
          alt: "Image 1 for carousel",
        },
        {
          src: fanbox2,
          alt: "Image 1 for carousel",
        },
      ],
      description: "RAJAL FAN BOX",
      color: "Color: MILK WHITE",
      rate: "Rs.42/piece  + GST",
    },
    {
      images: [
        {
          src: ceilingplaterajal1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "Rajal Ceiling Plate",
      color: "Color: MILK WHITE",
      rate: "Rs.3.5/piece  + GST",
    },
    {
      images: [
        {
          src: bend19mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "RAJAL 19MM BEND",
      color: "Color: IVARY",
      rate: "Rs.3/piece  + GST",
    },
    {
      images: [
        {
          src: bend25mm1,
          alt: "Image 1 for carousel",
        },
      ],
      description: "RAJAL 25MM BEND",
      color: "Color: IVARY",
      rate: "Rs.4/piece  + GST",
    },
    {
      images: [
        {
          src: spotbox1,
          alt: "Image 1 for carousel",
        },
        {
          src: spotbox2,
          alt: "Image 1 for carousel",
        },
      ],
      description: "RAJAL SPOT BOX",
      color: "Color: MILK WHITE",
      rate: "Rs.14/piece  + GST",
    },
  ];
  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <Navbar
        kamalslidesDatas={kamalslidesDatas}
        rajalslidesDatas={rajalslidesDatas}
      />
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Slider />
              <WelcomeSection />
              <Mainpage />
            </div>
          }
        />
        <Route
          path="/kamal"
          element={
            <Products title="Kamal Products" slidesDatas={kamalslidesDatas} />
          }
        />
        <Route
          path="/rajal"
          element={
            <Products title="Rajal Products" slidesDatas={rajalslidesDatas} />
          }
        />
        <Route path="/packing" element={<Packing />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
