import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import { useState } from "react";
import DropDown from "../DropDownMenu";

const Links = ({ kamalslidesDatas, rajalslidesDatas }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisible2, setDropdownVisible2] = useState(false);
  const handleMouseEnter = (e) => {
    const element = e.currentTarget.parentNode.parentNode.className;
    if (element === "links-container") return;
    setDropdownVisible(true);
  };

  const handleMouseEnter2 = (e) => {
    const element = e.currentTarget.parentNode.parentNode.className;
    if (element === "links-container") return;
    setDropdownVisible2(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const handleMouseLeave2 = () => {
    setDropdownVisible2(false);
  };
  return (
    <div className="links">
      <div
        className="kamal_link"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink exact to="/kamal" activeClassName="active" className="link">
          Kamal Products
        </NavLink>
        <div className="dropdown_outer" onMouseEnter={handleMouseEnter}>
          {isDropdownVisible && (
            <DropDown list={kamalslidesDatas} name="kamal" />
          )}
        </div>
      </div>
      <div
        className="rajal_link"
        onMouseEnter={handleMouseEnter2}
        onMouseLeave={handleMouseLeave2}
      >
        <NavLink to="/rajal" activeClassName="active" className="link">
          Rajal Products
        </NavLink>
        <div className="dropdown_outer" onMouseEnter={handleMouseEnter2}>
          {isDropdownVisible2 && (
            <DropDown list={rajalslidesDatas} name="rajal" />
          )}
        </div>
      </div>
      <NavLink to="/packing" activeClassName="active" className="link">
        Our Packing
      </NavLink>
      <NavLink to="/about" activeClassName="active" className="link">
        About Us
      </NavLink>
      <NavLink to="/contact" activeClassName="active" className="link">
        Contact Us
      </NavLink>
      <div className="get-quote-border">
        {/* <NavLink to="/get-quote" activeClassName="active" className="link"> */}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://forms.gle/CNygdKRxZfUsAD4o6"
          className="link"
        >
          Get Quote
        </a>
        {/* </NavLink> */}
      </div>
    </div>
  );
};

export default Links;
