import { React, useEffect } from "react";
import ProductCard from "../ProductCard";
import Grid from "@mui/material/Grid";
// import data from "../../data/carouselData.json";
import underLine from "../../assets/images/underLine.png";
import { Element } from "react-scroll";
import "./index.scss";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Products = ({ title, slidesDatas }) => {
  const query = useQuery();
  const sectionId = query.get("section");

  useEffect(() => {
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [sectionId]);

  return (
    <div className="products-page-div">
      <div className="product-page-title">
        <span>{title}</span>
      </div>
      <div className="headingLineDiv">
        <img src={underLine} alt="underLine" className="underLine" />
      </div>
      <div className="products-div">
        <Grid container>
          {slidesDatas.map((slidesData, idx) => (
            // console.log(slidesData);
            <Grid item key={idx} xs={12} md={6} lg={3} id={`${idx + 1}`}>
              <Element name={`${slidesData.description}`}>
                <ProductCard data={slidesData} />;
              </Element>
            </Grid>
          ))}
        </Grid>
        {/* <ProductCard data={slidesData} /> */}
      </div>
    </div>
  );
};

export default Products;
