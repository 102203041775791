import React from "react";
import "./index.scss";
import { useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const ProductCard = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [slide, setSlide] = useState(0);
  const { images, description, color, rate } = data;

  const nextSlide = () => {
    setSlide(slide === images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? images.length - 1 : slide - 1);
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="product-card">
      <div className="card-container">
        <div className="carousel">
          {images.length !== 1 && (
            <BsArrowLeftCircleFill
              onClick={prevSlide}
              className="arrow arrow-left"
            />
          )}
          {images.map((item, idx) => {
            return (
              <img
                src={item.src}
                alt={item.alt}
                key={idx}
                onClick={() => openModal(`${item.src}`)}
                className={slide === idx ? "slide" : "slide slide-hidden"}
              />
            );
          })}
          {images.length !== 1 && (
            <BsArrowRightCircleFill
              onClick={nextSlide}
              className="arrow arrow-right"
            />
          )}
          {images.length !== 1 && (
            <span className="indicators">
              {images.map((_, idx) => {
                return (
                  <button
                    key={idx}
                    className={
                      slide === idx
                        ? "indicator"
                        : "indicator indicator-inactive"
                    }
                    onClick={() => setSlide(idx)}
                  ></button>
                );
              })}
            </span>
          )}
        </div>
        <main className="main-content">
          <h1>{description}</h1>
          <p>{color}</p>
          <div className="flex-row">
            <div className="coin-base">
              <button className="rate-button">{rate}</button>
            </div>
          </div>
        </main>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modalbox">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <img
              className="modal-content"
              src={currentImage}
              alt="Large Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
