import { React } from "react";
import "./index.scss";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const DropDown = (props) => {
  const history = useNavigate();
  const items = props.list;
  const name = props.name;
  const handleLinkClick = (sectionId) => {
    console.log(sectionId);
    history(`/${name}?section=${sectionId}`);
  };

  return (
    <div className="dropdown-menu">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Link
              to={`${item.description}`}
              smooth={true}
              duration={500}
              onClick={(e) => {
                e.preventDefault();
                handleLinkClick(index + 1);
              }}
              value={index + 1}
            >
              {item.description}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;
